import { LocalizationString } from '@celito.clients/assets';
import { LayoutWithValueConfig } from '@celito.clients/types';
import { errorToast } from '@celito.clients/utils';
import { AxiosError } from 'axios';
import { Section } from 'libs/core/src/types/wizard-config';
import { useCallback } from 'react';
import { FieldValues, UseFormSetError } from 'react-hook-form';

import { FieldApiError } from '../components/form-wizard/form-wizard.model';
import { useFormEngineContext } from './useFormEngineContext';

export interface ErrorMessage {
  location: string;
  message: string;
}

export interface ErrorResponseData {
  errorMessage: ErrorMessage[];
  errorTitle: string;
  statusCode: number;
}

const useFormApiErrorHandler = () => {
  const { setFieldsApiErrors } = useFormEngineContext();
  const handleFormApiError = useCallback(
    (
      error: unknown,
      sections: Section[],
      setError?: UseFormSetError<FieldValues>
    ) => {
      const fieldErrors: FieldApiError[] = [];
      const axiosError = error as AxiosError;
      const sectionsWithErrors = new Set<number>();

      if (axiosError.response?.data) {
        const errorResponse = axiosError.response.data as ErrorResponseData;
        const toastErrors: string[] = [];

        if (Array.isArray(errorResponse.errorMessage)) {
          errorResponse.errorMessage.forEach((error) => {
            const field = sections
              .flatMap((sec, index) =>
                sec.fields.map((field) => ({ field, index }))
              )
              .find(({ field }) => field.columnName === error.location)?.field;

            if (field) {
              const sectionIndex = sections.findIndex((sec) =>
                sec.fields.includes(field)
              );
              sectionsWithErrors.add(sectionIndex);
            }

            const isVisible =
              field?.layoutConfiguration?.defaultLayoutRules?.isVisible ??
              !!(
                field?.layoutConfiguration
                  ?.layoutConfig as LayoutWithValueConfig
              )?.value?.isVisible;

            if (isVisible) {
              setError?.(error.location, {
                type: 'server side',
                message: error.message,
              });
              fieldErrors.push({
                field: error.location,
                message: error.message,
              });
            } else {
              toastErrors.push(error.message);
            }
          });
        } else {
          toastErrors.push(errorResponse.errorMessage);
        }

        if (toastErrors.length > 0) {
          errorToast({
            title: errorResponse.errorTitle,
            message: (
              <ul>
                {toastErrors.map((err) => (
                  <li key={err}>{err}</li>
                ))}
              </ul>
            ),
          });
        }
      }

      if (fieldErrors.length > 0) {
        if (!setError) {
          const sectionsIndexArray = Array.from(sectionsWithErrors);
          const sectionsLabels = sectionsIndexArray.map(
            (index) => sections[index].label
          );

          errorToast({
            title: LocalizationString.SUBMISION_FAILED,
            message: (
              <>
                <span>{LocalizationString.SUBMISION_ERROR}</span>
                {sectionsLabels.map((section) => (
                  <div key={section}>
                    {'- '}
                    {section}
                  </div>
                ))}
              </>
            ),
          });
        }

        setFieldsApiErrors?.({
          fields: fieldErrors,
          sections: Array.from(sectionsWithErrors),
        });
      }
    },
    [setFieldsApiErrors]
  );
  return { handleFormApiError };
};

export default useFormApiErrorHandler;
