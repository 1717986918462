import {
  AttributeTypeEnum,
  FormEngineModeTypeEnum,
  ObjectEnum,
} from '@celito.clients/enums';
import {
  useLayout,
  useObjectDefinition,
  useQueryParams,
  useView,
} from '@celito.clients/hooks';
import { getRecordDetailApi } from '@celito.clients/services';
import { Loader, useBreadcrumbs } from '@celito.clients/shared';
import { LayoutRulesDataSchema, OwnerType, Task } from '@celito.clients/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { Section } from 'libs/core/src/types/wizard-config';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';

import { FormEngineContextProvider } from '../../context';
import { WizardStateStatus } from '../../enums/wizard-enum';
import { useNonEmptyPrevious } from '../../hooks';
import { useLayoutRules } from '../../hooks/useLayoutRules';
import { getLifeCycleStageGroups } from '../../utils/attribute-config';
import { getFormEngineDefaultValues } from '../../utils/helper';
import { generateYupSchemaFromLayoutRules } from '../../utils/validator-generator';
import {
  ApiValidationError,
  FieldApiError,
  FormWizardComponentProps,
  WizardStepState,
} from './form-wizard.model';
import classes from './form-wizard.module.css';
import FormWizardView from './form-wizard.view';

export const FormWizardController = (props: FormWizardComponentProps) => {
  const { configureLayout } = useLayout();
  const { replaceLastBreadcrumb } = useBreadcrumbs();

  const [step, setStep] = useState(0);
  const [wizardState, setWizardState] = useState<string>(
    WizardStateStatus.UNSUBMITTED
  );
  const [sections, setSections] = useState<Section[]>();
  const [fieldsApiErrors, setFieldsApiErrors] = useState<ApiValidationError>(
    {} as ApiValidationError
  );
  const [recordData, setRecordData] = useState<Record<string, unknown>>({});
  const [wizardStepsState, setWizardStepsState] = useState<WizardStepState[]>(
    []
  );

  const [task, setTask] = useState<Task>();
  const [shouldRefetchTask, setShouldRefetchTask] = useState<boolean>(false);
  const [validationSchema, setValidationSchema] = useState<
    yup.ObjectSchema<yup.AnyObject>
  >({} as yup.ObjectSchema<yup.AnyObject>);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onSubmit',
    mode: 'all',
  });

  const navigate = useNavigate();
  const { getSearchParams } = useQueryParams();
  const [searchParam, setSearchParam] = useSearchParams();
  const searchParams = getSearchParams();
  const parentRecord = searchParam.get('pr');
  const requestType = searchParams?.requestType;

  const { data: viewDtoResponse, isLoading: isWizardConfigLoading } = useView({
    view: props.viewName!,
    queryOptions: {
      enabled: !!props.viewName,
    },
  });

  const hasTask = task && Object.keys(task)?.length > 0;

  const viewConfig = viewDtoResponse?.viewDto;
  const view = viewConfig?.view[0];
  const objectName = view?.objectName;

  const {
    data: objectDefinition,
    isLoading: isAttributeConfigLoading,
    refetch: refetchObjectDefinition,
  } = useObjectDefinition({
    objectName: (objectName ?? '') as ObjectEnum,
    recordName: props.recordName,
    version: (recordData?.version as string) ?? searchParams?.version,
    resolveDefaultValues: true,
    queryOptions: {
      enabled: !!objectName,
    },
  });

  // when version changes objectDefinition will be refetched thereby
  // becoming undefined. During this time, we use the previous objectDefinition
  const prevObjectDefinition = useNonEmptyPrevious(objectDefinition);

  const layoutType = view?.pageLayout;
  const totalSteps = sections?.length || 0;
  const submitUrl = view?.submitRedirectUrl || '';
  const editRouteLink = view?.editViewName;
  const listViewName = view?.listViewName;
  const showCancelPopup = view?.showCancelPopup;
  const labelFormat = objectDefinition?.labelFormat;
  const progressTabs =
    getLifeCycleStageGroups(
      objectDefinition!,
      recordData?.lifecycleStage as string
    ) ?? [];

  const {
    trigger,
    formState: { isDirty, errors },
    watch,
    reset,
    setError,
    getValues,
    setValue,
  } = methods;

  const formData = watch();
  // using stringified version to avoid multiple re-renders
  const formDataStringified = JSON.stringify(formData);
  const { fieldsState, setFieldsState } = useLayoutRules(
    formData,
    (sections?.[step] ?? []) as Section
  );
  const fieldsStateStringified = JSON.stringify(fieldsState);
  const dirtyFields = Object.keys(methods.formState.dirtyFields);

  const objDefinition = prevObjectDefinition ?? objectDefinition;
  const result: Record<string, { column: string; result: string[] }> = {};
  const filterReference = objDefinition?.objectAttributeDefinitions.filter(
    (attr) =>
      attr.dataType === AttributeTypeEnum.Reference &&
      attr.ownerType !== OwnerType.System
  );

  filterReference?.forEach(({ columnName, relationship }) => {
    const recordValue = recordData[columnName];
    if (recordValue && relationship?.objectName) {
      const isArray = Array.isArray(recordValue);
      const record = isArray ? recordValue[0] : recordValue;
      const columnType = record?.name ? 'name' : 'recordName';

      result[relationship.objectName] = {
        column: columnType,
        result: isArray
          ? recordValue.map((record) => record[columnType])
          : [(recordValue as Record<string, string>)[columnType]],
      };
    }
  });

  useEffect(() => {
    const fieldsStateParsed = JSON.parse(fieldsStateStringified) as Record<
      string,
      LayoutRulesDataSchema
    >;

    const newSchema = generateYupSchemaFromLayoutRules(
      watch(),
      sections?.[step]?.fields ?? [],
      objectDefinition?.objectValidationRules ?? [],
      objectDefinition?.objectAttributeDefinitions ?? [],
      fieldsStateParsed
    );

    if (
      newSchema &&
      typeof newSchema === 'object' &&
      Object.keys(newSchema).length > 0
    ) {
      setValidationSchema(newSchema);
    }

    if (errors && !isEmpty(errors)) {
      trigger(dirtyFields);
    }
  }, [
    formDataStringified,
    fieldsStateStringified,
    watch,
    sections?.[step]?.fields,
    step,
    objectDefinition?.objectAttributeDefinitions,
  ]);

  useEffect(() => {
    if (props.mode === FormEngineModeTypeEnum.Create && objectDefinition) {
      Object.keys(getValues())?.forEach((field) => {
        if (requestType && field !== 'initiation_type__a') {
          setValue(field, null);
        }
      });
      // Set new default values for the form
      reset({
        ...getValues(),
        ...getFormEngineDefaultValues(objectDefinition),
      });
      setRecordData({});
    }
  }, [objectDefinition?.name, getValues, reset, props.mode]);

  useEffect(() => {
    setStep(0);
  }, [props.mode]);

  useEffect(() => {
    if (view?.label && !requestType) {
      configureLayout({
        pageTitle: '',
        enablePadding: false,
        headerTitle: view?.label ?? '',
        showHeadingLoader:
          props.mode === FormEngineModeTypeEnum.Edit ? true : undefined,
      });
    }

    if (view?.sections && view.sections.length > 0) {
      setWizardStepsState(
        Array.from({ length: view.sections.length }).map((_, idx) => ({
          number: idx,
          isValid: undefined,
          isDirty: false,
          hasNavigated: false,
        }))
      );
      setSections(view.sections ?? []);
    }
  }, [view?.sections.length]);

  useEffect(() => {
    const removeSearchParam = (param: string) => {
      const updatedParams = new URLSearchParams(searchParams);
      const parentRecord = updatedParams.get(param);
      if (parentRecord) {
        updatedParams.delete(param);
        setSearchParam(updatedParams, { replace: true });
      }
    };

    return () => {
      removeSearchParam('pr');
    };
  }, [parentRecord]);

  const onStepClick = async (index: number) => {
    // validate the current step and advance.
    // for custom screens, validation is done at the custom screen level
    if (
      !sections?.[step]?.pageComponent &&
      props.mode !== FormEngineModeTypeEnum.View
    ) {
      const currentStepFields = (sections?.[step]?.fields ?? []).map(
        (field) => field.columnName
      );

      // validate only current section fields
      try {
        const isValid = await trigger(currentStepFields);
        setWizardStepsState((prev) =>
          prev.map((prevStep) =>
            prevStep.number === step ? { ...prevStep, isValid } : prevStep
          )
        );
      } catch (error) {
        // Handle error if needed
      }
    }

    setStep(index);
  };

  const goToPreviousStep = (e: React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();
    if (step > 0 && step <= totalSteps) {
      setStep((step) => step - 1);
    }
  };

  const goToNextStep = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();

    if (step <= totalSteps - 1) {
      setStep((prevStep) => prevStep + 1);
    }
  };

  const goToEditScreen = (
    e: React.MouseEvent<HTMLButtonElement>,
    _cb?: () => void
  ) => {
    e.preventDefault();

    const url = `../edit/${editRouteLink}/${props?.recordName}`;

    navigate(
      replaceLastBreadcrumb({
        path: url,
        label:
          (recordData?.label || recordData?.title || recordData?.name) + '',
      })
    );
  };

  useEffect(() => {
    if (recordData && Object.keys(recordData).length > 0) {
      configureLayout({
        pageTitle: '',
        enablePadding: false,
        headerTitle:
          ((recordData?.title as string) ||
            (recordData?.label as string) ||
            viewConfig?.view[0].label) ??
          '',
        showHeadingLoader: false,
      });
    }
  }, [objectDefinition, recordData]);

  const fetchRecordDetailsAPI = async (version: string | undefined) => {
    const response = await getRecordDetailApi(
      objectName,
      props.recordName!,
      version
        ? {
            version: version,
          }
        : {}
    );
    setRecordData(response);
    setShouldRefetchTask(true);
  };

  useEffect(() => {
    setWizardStepsState((prevSteps) =>
      prevSteps.map((prevStep) =>
        prevStep.number === step ? { ...prevStep, isDirty } : prevStep
      )
    );
  }, [isDirty, step]);

  useEffect(() => {
    const handleSectionErrors = (sections: number[]) => {
      setWizardStepsState((prev) =>
        prev.map((prevStep) =>
          sections.includes(prevStep.number)
            ? { ...prevStep, isValid: false }
            : prevStep
        )
      );
    };

    const handleFieldErrors = (errors: FieldApiError[]) => {
      errors.forEach((error) => {
        setError(error.field, {
          type: 'server side',
          message: error.message,
        });
      });
    };

    const timeoutId = setTimeout(() => {
      if (fieldsApiErrors.sections) {
        handleSectionErrors(fieldsApiErrors.sections);
      }
      if (fieldsApiErrors.fields) {
        handleFieldErrors(fieldsApiErrors.fields);
      }
    }, 1);

    return () => clearTimeout(timeoutId);
  }, [step, fieldsApiErrors]);

  const isWizardStateVisible =
    props.mode !== FormEngineModeTypeEnum.Create &&
    objectDefinition?.isLifecycleEnabled &&
    wizardState !== WizardStateStatus.UNSUBMITTED;

  if (
    isWizardConfigLoading ||
    (isAttributeConfigLoading && !prevObjectDefinition) // show loader only if object definition is not available(first time load)
  ) {
    return (
      <div className={classes.loader}>
        <Loader fullPage />
      </div>
    );
  }

  return (
    <FormEngineContextProvider
      {...{
        fieldsState,
        setFieldsState,
        isWizardStateVisible,
        isCompleterBarVisible: hasTask,
        fetchAttributeConfig: async () => {
          refetchObjectDefinition();
        },
        fieldsApiErrors,
        setFieldsApiErrors,
        setWizardStepsState,
        outboundRelationships: result,
        onCancel: props.onCancel,
        onSubmit: props.onSubmit,
      }}
    >
      <FormWizardView
        layout={layoutType!}
        viewName={props.viewName}
        objectName={objectName}
        stepsConfig={sections}
        setWizardConfig={setSections}
        attributeConfig={objDefinition}
        submitUrl={submitUrl}
        mode={props.mode}
        recordName={props.recordName}
        isLoaded={isWizardConfigLoading && isAttributeConfigLoading}
        editRoute={editRouteLink}
        step={step}
        setStep={setStep}
        wizardState={wizardState}
        setWizardState={setWizardState}
        totalSteps={totalSteps}
        goToPreviousStep={goToPreviousStep}
        goToNextStep={goToNextStep}
        goToEditScreen={goToEditScreen}
        onStepClick={onStepClick}
        listViewName={listViewName!}
        showCancelPopup={showCancelPopup!}
        labelFormat={labelFormat!}
        viewDto={viewConfig}
        progressTabs={progressTabs}
        recordData={recordData}
        setRecordData={setRecordData}
        methods={methods}
        wizardStepsState={wizardStepsState}
        setWizardStepsState={setWizardStepsState}
        fetchRecordDetailsAPI={fetchRecordDetailsAPI}
        shouldRefetchTask={shouldRefetchTask}
        task={task}
        setTask={setTask}
      />
    </FormEngineContextProvider>
  );
};
