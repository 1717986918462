import { Color } from '@celito.clients/theme';
import { makeStyles } from '@fluentui/react-components';

export const EntryCriteriasStyles = makeStyles({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '1rem',
  },

  criteriaContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    backgroundColor: Color.gray_100,
    gap: '8px',
    borderRadius: '4px',
  },

  btnAddCriteria: {
    width: 'auto',
    textAlign: 'left',
    fontSize: '16px',
    color: Color.blue_700,
    backgroundColor: 'transparent',
    border: '1px solid transparent',
    padding: '0px',
    margin: '0x',

    ':hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },

  deleteBtn: {
    color: Color.DELETE_ICON_COLOR,
    alignSelf: 'flex-end',
    visibility: 'initial',
  },

  visibilityOff: {
    visibility: 'hidden',
  },
});
