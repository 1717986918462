import { AttributeTypeEnum, OperatorsEnum } from '@celito.clients/enums';
import { raiseErrorToast } from '@celito.clients/utils';
import {
  SelectTabData,
  SelectTabEvent,
  TabValue,
} from '@fluentui/react-components';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { IOption } from '../in-house-input-select/in-house-input-select.model';
import {
  ICondition,
  IConditionType,
  IGroup,
  IRule,
  IRuleOption,
  RulesComponentProps,
} from './rules-component.model';
import { RulesComponentView } from './rules-component.view';
import { getAllObjects } from './services/get-all-objects';
import {
  RuleComponentEnum,
  RulesComponentListViewFilter,
} from './types/rules-component.types';
import {
  defineEmptyCondition,
  defineEmptyRule,
  getConditionsForDataType,
} from './utils/utils';

export const RulesComponentController = ({
  fields,
  methods,
  interfaceName = 'rules',
  ...props
}: RulesComponentProps) => {
  const [operatorOptions, setOperatorOptions] = useState<IRuleOption[]>([]);

  const [selectedValue, setSelectedValue] = useState<TabValue>(
    `${interfaceName}.${0}`
  );

  const path = useLocation();

  // On remove a condition from group, since I am only setting value, the useEffect has not been trigger which is causing issues with the operators. This ensure an dependecy array to trigger it.
  const [triggerOperatorUpdate, setTriggerOperatorUpdate] = useState(false);

  const [isLoadingObject, setIsLoadingObject] = useState(false);
  const [allObjectOptions, setAllObjectOptions] = useState<IOption[]>([]);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const [itemToDelete, setItemToDelete] = useState<number>();

  useEffect(() => {
    let options: IRuleOption[] = [];
    const rules: IRule[] = methods.watch(`${interfaceName}`, props.rules);

    // Need for reseting filters, fact was empty yet operator was not coming back to default
    if (
      rules?.length === 1 &&
      rules?.[0]?.groups?.length === 1 &&
      !methods.getValues(
        `${interfaceName}.${0}.groups.${0}.conditions.${0}.fact`
      ) &&
      methods.getValues(
        `${interfaceName}.${0}.groups.${0}.conditions.${0}.operator`
      )
    )
      methods.setValue(
        `${interfaceName}.${0}.groups.${0}.conditions.${0}.operator`,
        undefined
      );

    rules?.forEach((rule, index) => {
      rule.groups?.forEach((group, groupIndex) => {
        group.conditions?.forEach((condition, conditionIndex) => {
          if (
            methods.getValues(
              `${interfaceName}.${index}.groups.${groupIndex}.conditions.${conditionIndex}`
            )
          ) {
            options = [
              ...options,
              handleGetOperatorOptions(
                index,
                groupIndex,
                conditionIndex,
                fields.find(
                  (field) =>
                    field.value ===
                    methods.getValues(
                      `${interfaceName}.${index}.groups.${groupIndex}.conditions.${conditionIndex}.fact`
                    )
                )?.dataType
              ),
            ];

            if (
              methods.getValues(
                `${interfaceName}.${index}.groups.${groupIndex}.conditions.${conditionIndex}.fact`
              )
            ) {
              setOperatorOptions(options);
            }
          }
        });
      });
    });

    setTriggerOperatorUpdate(false);
  }, [
    triggerOperatorUpdate,
    methods,
    interfaceName,
    props.rules,
    fields,
    methods.watch(`${interfaceName}`),
  ]);

  const getInputTypeFromColumnAndCondition = (
    dataType: string,
    operator: OperatorsEnum
  ) => {
    switch (operator) {
      case OperatorsEnum.IS_BLANK:
      case OperatorsEnum.IS_NOT_BLANK:
      case OperatorsEnum.IN_PAST:
        return AttributeTypeEnum.None;

      case OperatorsEnum.IS:
        return AttributeTypeEnum.Dropdown;

      case OperatorsEnum.IN_LAST_DAYS:
      case OperatorsEnum.IN_LAST_MONTHS:
      case OperatorsEnum.IN_LAST_WEEKS:
      case OperatorsEnum.IN_NEXT_DAYS:
      case OperatorsEnum.IN_NEXT_MONTHS:
      case OperatorsEnum.IN_NEXT_WEEKS:
        return AttributeTypeEnum.Number;

      case OperatorsEnum.RANGE:
        if (
          dataType === AttributeTypeEnum.Date ||
          dataType === AttributeTypeEnum.DateTime
        ) {
          return AttributeTypeEnum.DateRange;
        }
        return AttributeTypeEnum.NumberRange;

      default:
        if (dataType === AttributeTypeEnum.RichText) {
          return AttributeTypeEnum.PlainText;
        } else {
          return dataType || AttributeTypeEnum.PlainText;
        }
    }
  };

  const handleSetOperatorOptions = (
    index: number,
    groupIndex: number,
    conditionIndex: number,
    dataType?: AttributeTypeEnum
  ) => {
    const operators = getConditionsForDataType(dataType);

    const operatorOptionsMap = operators.map((operator) => {
      return { value: operator.key as string, text: operator.text ?? '' };
    });

    let updatedOptions: IRuleOption[] = [];

    if (operatorOptions.length > 0)
      if (
        operatorOptions.some(
          (opt) =>
            opt.ruleIndex === index &&
            opt.groupIndex === groupIndex &&
            opt.conditionIndex === conditionIndex
        )
      )
        updatedOptions = operatorOptions.map((option) => {
          if (
            option.ruleIndex === index &&
            option.groupIndex === groupIndex &&
            option.conditionIndex === conditionIndex
          ) {
            option.options = operatorOptionsMap;
            option.groupIndex = groupIndex;
            option.conditionIndex = conditionIndex;
            option.ruleIndex = index;
          }

          return option;
        });
      else {
        updatedOptions = [
          ...operatorOptions,
          {
            ruleIndex: index,
            groupIndex,
            conditionIndex,
            options: operatorOptionsMap,
          },
        ];
      }
    else
      updatedOptions = [
        {
          ruleIndex: index,
          groupIndex,
          conditionIndex,
          options: operatorOptionsMap,
        },
      ];

    setOperatorOptions(updatedOptions);
  };

  const handleGetOperatorOptions = (
    index: number,
    groupIndex: number,
    conditionIndex: number,
    dataType?: AttributeTypeEnum
  ) => {
    const operators = getConditionsForDataType(dataType);

    const operatorOptionsMap = operators.map((operator) => {
      return { value: operator.key as string, text: operator.text ?? '' };
    });

    return {
      groupIndex,
      ruleIndex: index,
      conditionIndex,
      options: operatorOptionsMap,
    };
  };

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value);

    props.onSelectTabFunction &&
      props.onSelectTabFunction(data.value as unknown as string);
  };

  const addConditionToGroup = (
    ruleIndex: number,
    groupIndex: number,
    group: IGroup
  ) => {
    const currentConditions = group.conditions;

    const newCondition: ICondition = defineEmptyCondition();

    methods.setValue(
      `${interfaceName}.${ruleIndex}.groups.${groupIndex}.conditions`,
      [...currentConditions, newCondition],
      {
        shouldDirty: true,
      }
    );
  };

  const removeConditionFromGroup = (
    ruleIndex: number,
    groupIndex: number,
    conditionIndex: number,
    condition: ICondition[]
  ) => {
    methods.setValue(
      `${interfaceName}.${ruleIndex}.groups.${groupIndex}.conditions`,
      condition.filter((rule, index) => index !== conditionIndex),
      { shouldDirty: true }
    );

    methods.clearErrors();

    setTriggerOperatorUpdate(true);
  };

  const handleSetConditionType = (ruleIndex: number, type: IConditionType) => {
    methods.setValue(`${interfaceName}.${ruleIndex}.conditionType`, type, {
      shouldDirty: true,
    });
  };

  const handleSetGroupConditionType = (
    ruleIndex: number,
    groupIndex: number,
    type: IConditionType
  ) => {
    methods.setValue(
      `${interfaceName}.${ruleIndex}.groups.${groupIndex}.conditionType`,
      type,
      {
        shouldDirty: true,
      }
    );
  };

  const addGroupToRule = (index: number, groups: IGroup[]) => {
    const newGroup: IGroup = {
      conditionType: 'all',
      conditions: [defineEmptyCondition()],
    };

    const updatedGroups: IGroup[] = [...groups, newGroup];

    methods.setValue(`${interfaceName}.${index}.groups`, updatedGroups, {
      shouldDirty: true,
    });
  };

  const removeGroupFromRole = (
    index: number,
    groupIndex: number,
    groups: IGroup[],
    rule: IRule
  ) => {
    const updatedGroups = groups.filter(
      (group, indexToRemove) => indexToRemove !== groupIndex
    );

    const isSingleRule = props.ruleLayout === RuleComponentEnum.SingleRule;

    if (isSingleRule && updatedGroups.length === 0) {
      methods.setValue(
        `${interfaceName}`,
        [{ ...rule, ...defineEmptyRule()[0] }],
        {
          shouldDirty: true,
        }
      );
    } else if (updatedGroups.length === 0 && !isSingleRule)
      methods.setValue(
        `${interfaceName}.${index}`,
        { ...rule, ...defineEmptyRule(`${rule.label}`)[0] },
        {
          shouldDirty: true,
        }
      );
    else
      methods.setValue(`${interfaceName}.${index}.groups`, updatedGroups, {
        shouldDirty: true,
      });

    if (updatedGroups.length === 0) {
      methods.trigger(`${interfaceName}`);
    }

    setOperatorOptions([]);

    const rules: IRule[] = methods.watch(`${interfaceName}`, props.rules);
    let options: IRuleOption[] = [];

    rules.map((rule, index) => {
      rule.groups.map((group, groupIndex) => {
        group.conditions.map((condition, conditionIndex) => {
          if (
            methods.getValues(
              `${interfaceName}.${index}.groups.${groupIndex}.conditions.${conditionIndex}`
            )
          ) {
            options = [
              ...options,
              handleGetOperatorOptions(
                index,
                groupIndex,
                conditionIndex,
                fields.find(
                  (field) =>
                    field.value ===
                    methods.getValues(
                      `${interfaceName}.${index}.groups.${groupIndex}.conditions.${conditionIndex}.fact`
                    )
                )?.dataType
              ),
            ];

            setOperatorOptions(options);
          }
        });
      });
    });

    methods.clearErrors();
  };

  const onDeleteClick = (index: number) => {
    setItemToDelete(index);
    handleDeleteModal();
  };

  const handleDeleteModal = () => {
    setIsDeleteDialogOpen((prev) => !prev);
  };

  const fetchAllObjects = async () => {
    const isAdminModule = path?.pathname?.split('/')?.[2] === 'admin__a';

    const filters: RulesComponentListViewFilter[] = [
      {
        conditions: {
          all: [
            {
              all: [
                {
                  attribute: 'moduleName',
                  operator: OperatorsEnum.EQUALS,
                  value: path?.pathname?.split('/')?.[2] ?? 'lms__s',
                },
              ],
            },
          ],
        },
      },
    ];

    const configPayload = {
      pageNumber: 1,
      limit: 999,
      viewName: 'object_definition_object_name__s',
      filters: isAdminModule ? [] : filters,
    };

    setIsLoadingObject(true);

    getAllObjects(configPayload)
      .then((res) => {
        const options = res?.data?.map(
          (itm: { name: string; label: string }) => {
            return { value: itm.name, text: itm.label };
          }
        );

        setAllObjectOptions(options);
      })
      .catch((_error) => {
        raiseErrorToast(_error);
      })
      .finally(() => setIsLoadingObject(false));
  };

  const handleManualSelectTab = (index: number) => {
    setSelectedValue(`${interfaceName}.${index === 0 ? index : index - 1}`);
  };

  return (
    <RulesComponentView
      {...{
        fields,
        methods,
        handleSetOperatorOptions,
        operatorOptions,
        addConditionToGroup,
        removeConditionFromGroup,
        handleSetConditionType,
        handleSetGroupConditionType,
        getInputTypeFromColumnAndCondition,
        addGroupToRule,
        removeGroupFromRole,
        onTabSelect,
        selectedValue,
        onDeleteClick,
        handleDeleteModal,
        isDeleteDialogOpen,
        itemToDelete,
        fetchAllObjects,
        isLoadingObject,
        allObjectOptions,
        handleManualSelectTab,
        interfaceName,
        ...props,
      }}
    />
  );
};
