import { makeStyles } from '@fluentui/react-components';

export const workflowSettingsStyles = makeStyles({
  settingsContainer: {
    padding: '1.5rem',
    display: 'grid',
    gap: '1rem',
  },

  row: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '3rem',
    '> div': {
      gridColumn: 'span 2',
    },
  },
});
