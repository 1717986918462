import { FormEngineModeTypeEnum } from '@celito.clients/enums';
import { useRecordNavigation } from '@celito.clients/provider';
import { useEffect } from 'react';

import { RelatedObjectsProps } from './related-objects.model';
import { RelatedObjectsView } from './related-objects.view';

interface RelatedObjectsControllerProps extends RelatedObjectsProps {}

export const RelatedObjectsController = (
  props: RelatedObjectsControllerProps
): JSX.Element => {
  const { updateCurrentNavItem } = useRecordNavigation();

  useEffect(() => {
    if (props?.recordName && props.objectName) {
      updateCurrentNavItem({ isRelatedObject: true, page: 1, currentIndex: 0 });
    }
    if (props.mode === FormEngineModeTypeEnum.View) {
      return;
    }

    props.setWizardStepsState((prev) =>
      prev.map((prevStep) =>
        prevStep.number === props.step
          ? { ...prevStep, isValid: true }
          : prevStep
      )
    );
  }, []);

  return <RelatedObjectsView {...props} />;
};
