import { RadioButton, TextField } from '@celito.clients/shared';
import { Controller, useFormContext } from 'react-hook-form';

import {
  ScheduleTypeEnum,
  scheduleTypeOptions,
} from '../create-workflow-modal.model';
import { ScheduledSettingsProps } from './scheduled-settings.model';
import { workflowSettingsStyles } from './scheduled-settings.styles';

export const ScheduledSettingsView = (props: ScheduledSettingsProps) => {
  const styles = workflowSettingsStyles();
  const { control, watch } = useFormContext();

  const scheduleType = watch('scheduleType');
  return (
    <div className={styles.settingsContainer}>
      <Controller
        control={control}
        name="scheduleType"
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <RadioButton
            onChange={onChange}
            layout="vertical"
            options={scheduleTypeOptions}
            label="Schedule type"
            style={{
              marginTop: -2,
            }}
            value={value}
            required
            errorMessage={error?.message}
          />
        )}
      />
      {scheduleType === ScheduleTypeEnum.ADVANCED && (
        <div className={styles.row}>
          <Controller
            control={control}
            name="executionSchedule"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                onChange={onChange}
                value={value}
                label="CRON expression"
                required
                size="large"
                errorMessage={error?.message}
              />
            )}
          />
        </div>
      )}
    </div>
  );
};
