import { IOption } from 'libs/shared/src/lib/in-house-input-select/in-house-input-select.model';

export interface CreateWorkflowProps {
  dataTestId?: string;
  objectNameOptions: ObjectNameOptions[];
}

export type ObjectNameOptions = {
  text: string;
  value: string;
};

export interface CreateWorkflowModalViewProps {
  dataTestId?: string;
  objectNameOptions: ObjectNameOptions[];
  openWorkFlowModal: boolean;
  handleWorkflowModalOpen: () => void;
  onCreateWorkflowSubmit: (data: any) => Promise<void>;
  isLoading?: boolean;
}

export interface CreateWorkflowControllerProps {
  openWorkFlowModal: boolean;
  handleWorkflowModalOpen: () => void;
}
export interface LifeCycleEventOption {
  text: string;
  value: string;
}
export const lifeCycleEventOptions: LifeCycleEventOption[] = [
  { text: 'Create', value: 'CREATE' },
  { text: 'Delete', value: 'DELETE' },
  { text: 'Lifecycle', value: 'LIFECYCLE' },
  { text: 'Edit', value: 'EDIT' },
];

export enum ExecutionTypeEnum {
  SCHEDULED = 'SCHEDULED',
  USER_TRIGGERED = 'USER-TRIGGERED',
  OBJECT_EVENT = 'OBJECT-EVENT',
}

export const executionTypeOptions = [
  { text: 'Scheduled', value: ExecutionTypeEnum.SCHEDULED },
  { text: 'User-Triggered', value: ExecutionTypeEnum.USER_TRIGGERED },
  { text: 'Object-Event', value: ExecutionTypeEnum.OBJECT_EVENT },
];

export enum ScheduleTypeEnum {
  BASIC = 'basic',
  ADVANCED = 'advanced',
}

export const scheduleTypeOptions = [
  { label: 'Basic', value: ScheduleTypeEnum.BASIC as string },
  { label: 'Advanced', value: ScheduleTypeEnum.ADVANCED as string },
];

export interface SelectedObject {
  optionValue?: string;
  optionText?: string;
  selectedOptions?: IOption[] | IOption;
}

export type FormValues = {
  label: string;
  objectName: string;
  description: string;
  executionType: string;
  lifeCycleEvent: string;
  executionSchedule: string;
  scheduleType: string;
};
