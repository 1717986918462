import { ScheduledSettingsControllerProps } from './scheduled-settings.model';
import { ScheduledSettingsView } from './scheduled-settings.view';

const WorkflowSettingsController = (
  props: ScheduledSettingsControllerProps
) => {
  return <ScheduledSettingsView {...props} />;
};

export default WorkflowSettingsController;
