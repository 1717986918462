import { makeStyles, shorthands } from '@fluentui/react-components';

export const ComponentActionsStyles = makeStyles({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.gap('1rem'),
    ...shorthands.padding('1rem'),
  },

  thenContainer: {
    display: 'flex',
    alignItems: 'center',

    ...shorthands.gap('50px'),
  },

  thenInnerContainer: {
    ...shorthands.padding('1rem'),
    fontSize: '1rem',
    backgroundColor: 'rgba(82, 196, 26, 0.10)',
    width: '100%',
  },
});
