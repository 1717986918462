import type { PartitionBreadcrumbItems } from '@fluentui/react-components';
import {
  Breadcrumb as FluentBreadcrumb,
  BreadcrumbButton,
  BreadcrumbDivider,
  BreadcrumbItem,
  Button,
  Menu,
  MenuItemLink,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Overflow,
  OverflowDivider,
  OverflowItem,
  partitionBreadcrumbItems,
  useIsOverflowItemVisible,
  useOverflowMenu,
} from '@fluentui/react-components';
import {
  bundleIcon,
  MoreHorizontalFilled,
  MoreHorizontalRegular,
} from '@fluentui/react-icons';
import cn from 'classnames';
import * as React from 'react';
import { useNavigate } from 'react-router';

import { useBreadcrumbStyles } from './breadcrumb.styles';

const MoreHorizontal = bundleIcon(MoreHorizontalFilled, MoreHorizontalRegular);

type Item = {
  key: number;
  item: string;
};

const MenuItem: React.FC<{
  id: string;
  item: Item;
  handleBreadcrumItemClick: (index: number) => void;
}> = (props) => {
  const { item, id, handleBreadcrumItemClick } = props;
  const isVisible = useIsOverflowItemVisible(id);

  if (isVisible) {
    return null;
  }

  return (
    <MenuItemLink
      icon={null}
      onClick={(e) => {
        e.preventDefault();
        handleBreadcrumItemClick(item.key);
      }}
      href=""
    >
      {item.item}
    </MenuItemLink>
  );
};

const OverflowGroupDivider: React.FC<{
  groupId: number;
}> = (props) => {
  const styles = useBreadcrumbStyles();
  return (
    <OverflowDivider groupId={props.groupId.toString()}>
      <BreadcrumbDivider
        data-group={props.groupId}
        className={styles.divider}
      />
    </OverflowDivider>
  );
};

type RenderBreadcrumbItemProps = {
  el: Item;
  isLastItem: boolean;
  handleBreadcrumItemClick: (index: number) => void;
};
const RenderBreadcrumbItem = ({
  el,
  isLastItem = false,
  handleBreadcrumItemClick,
}: RenderBreadcrumbItemProps) => {
  const styles = useBreadcrumbStyles();
  return (
    <React.Fragment key={`button-items-${el.key}`}>
      <OverflowItem
        id={el.key.toString()}
        priority={isLastItem ? el.key : undefined}
        groupId={el.key.toString()}
      >
        <BreadcrumbItem>
          <BreadcrumbButton
            current={isLastItem}
            className={cn({
              [styles.breacumbButtonHover]: !isLastItem,
              [styles.breacumbButton]: isLastItem,
            })}
            onClick={(e) => handleBreadcrumItemClick(el.key)}
          >
            {el.item}
          </BreadcrumbButton>
        </BreadcrumbItem>
      </OverflowItem>
      {!isLastItem && <OverflowGroupDivider groupId={el.key} />}
    </React.Fragment>
  );
};

interface OverflowMenuProps extends PartitionBreadcrumbItems<Item> {
  handleBreadcrumItemClick: (index: number) => void;
}

const OverflowMenu = (props: OverflowMenuProps) => {
  const {
    overflowItems,
    startDisplayedItems,
    endDisplayedItems,
    handleBreadcrumItemClick,
  } = props;

  const { ref, isOverflowing, overflowCount } =
    useOverflowMenu<HTMLButtonElement>();
  const styles = useBreadcrumbStyles();

  if (!isOverflowing && overflowItems && overflowItems.length === 0) {
    return null;
  }

  const overflowItemsCount = overflowItems
    ? overflowItems.length + overflowCount
    : overflowCount;

  if (overflowCount === 0) {
    return null;
  }

  return (
    <>
      <BreadcrumbItem>
        <Menu hasIcons>
          <MenuTrigger disableButtonEnhancement>
            <Button
              id="menu"
              appearance="subtle"
              ref={ref}
              icon={<MoreHorizontal />}
              aria-label={`${overflowItemsCount} more items`}
              className={styles.moreButton}
            />
          </MenuTrigger>
          <MenuPopover>
            <MenuList>
              {isOverflowing &&
                startDisplayedItems.map((item: Item) => (
                  <MenuItem
                    id={item.key.toString()}
                    item={item}
                    key={item.key}
                    handleBreadcrumItemClick={handleBreadcrumItemClick}
                  />
                ))}
              {overflowItems?.map((item: Item) => (
                <MenuItem
                  id={item.key.toString()}
                  item={item}
                  key={item.key}
                  handleBreadcrumItemClick={handleBreadcrumItemClick}
                />
              ))}
              {isOverflowing &&
                endDisplayedItems?.map((item: Item) => (
                  <MenuItem
                    id={item.key.toString()}
                    item={item}
                    key={item.key}
                    handleBreadcrumItemClick={handleBreadcrumItemClick}
                  />
                ))}
            </MenuList>
          </MenuPopover>
        </Menu>
      </BreadcrumbItem>
      <BreadcrumbDivider className={styles.divider} />
    </>
  );
};

export const Breadcrumb = ({ items }: { items: string[] }) => {
  const navigate = useNavigate();

  const itemsWithKey = items.map((item: string, index: number) => ({
    key: index,
    item,
  }));

  const {
    startDisplayedItems,
    overflowItems,
    endDisplayedItems,
  }: PartitionBreadcrumbItems<Item> = partitionBreadcrumbItems({
    items: itemsWithKey,
    maxDisplayedItems: Infinity,
  });

  const handleBreadcrumItemClick = (index: number) => {
    if (index < items.length - 1) {
      navigate(-(items.length - 1 - index));
    }
  };

  return (
    <Overflow minimumVisible={2}>
      <FluentBreadcrumb size="large">
        {startDisplayedItems.map((item: Item) => (
          <RenderBreadcrumbItem
            key={item.key}
            el={item}
            isLastItem={!endDisplayedItems}
            handleBreadcrumItemClick={handleBreadcrumItemClick}
          />
        ))}

        {endDisplayedItems?.map((item: Item) => {
          const isLastItem = item.key === items.length - 1;
          return (
            <React.Fragment key={item.key}>
              {isLastItem && (
                <OverflowMenu
                  overflowItems={overflowItems}
                  startDisplayedItems={startDisplayedItems}
                  endDisplayedItems={endDisplayedItems}
                  handleBreadcrumItemClick={handleBreadcrumItemClick}
                />
              )}
              <RenderBreadcrumbItem
                el={item}
                isLastItem={isLastItem}
                handleBreadcrumItemClick={handleBreadcrumItemClick}
              />
            </React.Fragment>
          );
        })}
      </FluentBreadcrumb>
    </Overflow>
  );
};
