import { LocalizationString } from '@celito.clients/assets';
import { raiseErrorToast, successToast } from '@celito.clients/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import cron from 'cron-validate';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import * as yup from 'yup';

import { createWorkflowAPI, getObjectName } from '../../services';
import {
  CreateWorkflowControllerProps,
  ExecutionTypeEnum,
  FormValues,
  ObjectNameOptions,
  ScheduleTypeEnum,
} from './create-workflow-modal.model';
import { CreateWorklowModalView } from './create-workflow-modal.view';

const schema = yup.object().shape({
  label: yup.string().required(LocalizationString.REQUIRED_MSG).min(5).max(128),
  objectName: yup.string().required(LocalizationString.REQUIRED_MSG),
  description: yup.string().notRequired(),
  executionType: yup.string().required(LocalizationString.REQUIRED_MSG),
  lifeCycleEvent: yup.string().when(['executionType'], ([executionType]) => {
    return executionType === ExecutionTypeEnum.OBJECT_EVENT
      ? yup.string().required(LocalizationString.REQUIRED_MSG)
      : yup.string().notRequired();
  }),
  scheduleType: yup.string().when(['executionType'], ([executionType]) => {
    return executionType === ExecutionTypeEnum.SCHEDULED
      ? yup.string().required(LocalizationString.REQUIRED_MSG)
      : yup.string().notRequired();
  }),
  executionSchedule: yup.string().when(['scheduleType'], ([scheduleType]) => {
    return scheduleType === ScheduleTypeEnum.ADVANCED
      ? yup
          .string()
          .required(LocalizationString.REQUIRED_MSG)
          .test('invalid-cron-expression', 'Invalid expression', (value) => {
            const items = value.split(' ');
            const cronWIthSeconds = items.length > 5;

            return cron(value, {
              preset: 'default',
              override: {
                useSeconds: cronWIthSeconds,
              },
            }).isValid();
          })
      : yup.string().notRequired();
  }),
}) as yup.ObjectSchema<FormValues>;

const CreateWorkflowController = (props: CreateWorkflowControllerProps) => {
  const [objectNameOptions, setObjectNameOptions] = useState<
    ObjectNameOptions[]
  >([]);

  const methods = useForm<FormValues>({
    defaultValues: {
      label: '',
      objectName: '',
      description: '',
      executionType: '',
      lifeCycleEvent: '',
      scheduleType: '',
      executionSchedule: '',
    },
    resolver: yupResolver(schema),
    mode: 'all',
    shouldUnregister: false,
    reValidateMode: 'onChange',
  });

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const onCreateWorkflowSubmit = async (data: FormValues) => {
    setIsLoading(true);
    let payload: {
      label: string;
      description: string;
      objectName: string;
      executionType: string;
      executionSchedule?: string;
      executionEvent?: string;
    } = {
      label: data.label,
      description: data.description,
      objectName: data.objectName,
      executionType: data.executionType,
    };

    if (data.executionType === ExecutionTypeEnum.SCHEDULED) {
      payload = { ...payload, executionSchedule: data.executionSchedule };
    }

    if (data.executionType === ExecutionTypeEnum.OBJECT_EVENT) {
      payload = { ...payload, executionEvent: data.lifeCycleEvent };
    }
    await createWorkflowAPI(payload)
      .then((res: any) => {
        successToast({ message: 'Successfully created workflow' });
        setIsLoading(false);
        navigate(
          `../custom/workflows/node_editor/${res.data.name}/${res.data.version}`
        );
      })
      .catch((_error) => {
        raiseErrorToast(_error);
      });
  };
  useEffect(() => {
    getObjectName()
      .then((res: any) => {
        const tempList: any = [];
        res?.data?.forEach((option: any) => {
          tempList.push({
            text: option.label,
            value: option.name,
            isVersioningEnabled: option.isVersioningEnabled,
          });
        });
        setObjectNameOptions(tempList);
      })
      .catch((_error) => {
        raiseErrorToast(_error);
      });
  }, []);

  return (
    <FormProvider {...methods}>
      <CreateWorklowModalView
        objectNameOptions={objectNameOptions}
        onCreateWorkflowSubmit={onCreateWorkflowSubmit}
        isLoading={isLoading}
        {...props}
      />
    </FormProvider>
  );
};

export default CreateWorkflowController;
