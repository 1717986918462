import { queryClient } from '@celito.clients/endpoints';
import { ApiMethodType } from '@celito.clients/enums';
import { performRequest } from '@celito.clients/network';
import Endpoints from 'libs/core/src/config/endpoint';

import { ObjectMetadata } from '../types';

export const getObjectMetadata = async (
  objectName: string,
  resolveDefaultValues = true
): Promise<ObjectMetadata> => {
  const cachedObjectMetadata = queryClient.getQueryData<ObjectMetadata>([
    objectName,
    'definition',
  ]);

  if (cachedObjectMetadata) {
    return cachedObjectMetadata;
  }

  const response = await performRequest<ObjectMetadata>(
    `${Endpoints.OBJECT_METADATA_URL}/${objectName}?resolve_default_values=${resolveDefaultValues}`,
    ApiMethodType.GET,
    null
  );

  queryClient.setQueryData([objectName, 'definition'], response.data);

  return response.data;
};
