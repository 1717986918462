import { DeleteIcon, LocalizationString } from '@celito.clients/assets';
import {
  ButtonTypes,
  DateFormat,
  FormEngineModeTypeEnum,
} from '@celito.clients/enums';
import { UserContext } from '@celito.clients/provider';
import { formatDate, isDateValid } from '@celito.clients/utils';
import { makeStyles } from '@fluentui/react-components';
import { useDisplayViewContext } from 'libs/form-engine/src/lib/components/view-components/display-view/display-view.context';
import React, { useContext } from 'react';

import {
  ConfirmDialog,
  CustomButton,
  GridView,
  Loader,
  Stack,
} from '../../shared';
import { DisplayFilesViewProps } from './multi-file-view.model';

const useStyles = makeStyles({
  btn: {
    backgroundColor: 'transparent',

    ':hover': {
      backgroundColor: 'transparent',
    },
  },
  grid_view_container: {
    marginTop: '2px',
  },
});

const MultiFileViewView: React.FC<DisplayFilesViewProps> = ({
  isLoading,
  removeFile,
  fileNames,
  uploadedDate = new Date(),
  mode = FormEngineModeTypeEnum.View,
  handleDownload,
  documentIds,
  showDownloadBtn = false,
  disabled = false,
  uploadedBy = [],
  uploadedAtUtc = [],
}) => {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const [isOpen, setIsOpen] = React.useState(false);
  const toBeDeletedItem = React.useRef<Record<string, unknown> | undefined>();

  const { isPreviewTab } = useDisplayViewContext();

  const handleOpenDeleteDialog =
    (item: Record<string, unknown>) =>
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      toBeDeletedItem.current = item;
      setIsOpen(true);
    };

  return isLoading ? (
    <Loader />
  ) : (
    <Stack>
      <ConfirmDialog
        open={isOpen}
        onCancelClicked={(e) => {
          e.stopPropagation();
          toBeDeletedItem.current = undefined;
          setIsOpen(false);
        }}
        onConfirmClicked={(e) => {
          e.stopPropagation();
          removeFile!(
            toBeDeletedItem.current?.title as string,
            toBeDeletedItem.current?.key as number
          )();
          setIsOpen(false);
        }}
        primaryButtonText={LocalizationString.DELETE}
        title={LocalizationString.DELETE_RECORD}
        iconSrc={DeleteIcon}
        description={LocalizationString.ARE_YOU_SURE_YOU_WANT_TO_DELETE}
        secondaryButtonText={LocalizationString.CANCEL}
        isBlocking
      />
      <GridView
        columns={[
          {
            key: '1',
            name: 'File Name',
            minWidth: 100,
            maxWidth: 400,
            fieldName: 'title',
            isResizable: true,
          },
          {
            key: '2',
            name: 'Upload Date',
            minWidth: 100,
            maxWidth: 400,
            fieldName: 'uploadData',
            isResizable: true,
            disableAlphanumericSorting: true,
          },
          {
            key: '3',
            name: 'Uploaded By',
            minWidth: 100,
            maxWidth: 400,
            fieldName: 'uploadedBy',
            isResizable: true,
          },
          {
            key: '4',
            name: 'Action',
            minWidth: 80,
            maxWidth: 80,
            fieldName: 'action',
            isResizable: true,
            onRender: (item, _index, _column) => {
              return (
                <div style={{ display: 'flex' }}>
                  <CustomButton
                    as="button"
                    buttonType={ButtonTypes.Destructive}
                    customStyles={classes.btn}
                    leftIcon="Download"
                    title="Download file"
                    onClick={handleDownload(item.title)}
                    disabled={isPreviewTab || disabled}
                  />
                  {((mode !== FormEngineModeTypeEnum.View && removeFile) ||
                    isPreviewTab) && (
                    <CustomButton
                      as="button"
                      buttonType={ButtonTypes.Destructive}
                      leftIcon="Delete"
                      dataTestId="button-delete-attachments"
                      title="Remove file"
                      customStyles={classes.btn}
                      onClick={handleOpenDeleteDialog(item)}
                      disabled={isPreviewTab || disabled}
                    />
                  )}
                </div>
              );
            },
          },
        ]}
        items={fileNames?.map((file, index) => ({
          key: index,
          title: file,
          uploadData: isDateValid(new Date(uploadedAtUtc[index]))
            ? formatDate(new Date(uploadedAtUtc[index]), DateFormat.Date)
            : '-',
          uploadedBy:
            uploadedBy[index] === '' ? user?.label : uploadedBy[index],
          action: 'action',
        }))}
        disableSelection
        className={classes.grid_view_container}
      />
    </Stack>
  );
};

export default MultiFileViewView;
